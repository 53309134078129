import { StreamLoaderError, StreamLoaderErrors } from './error'
import { activateRedirect, activateTempPassRedirect } from './actions/redirect'
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly'
import { TEMP_PASS_REDIRECT } from '../BasePlayer'
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags'
import { DebugControllerSingleton } from '../../../util/debug/DebugController'
import { isNBCProfileLinked } from '../../../helpers'
import { openPlayerLoader } from '../../../widgets/Modals/playerLoader/PlayerLoader'
import { TOKEN_TYPE } from '../../../constants'

export enum StreamAuthorizationType {
  UNLOCKED = 'None',
  CREDIT = 'NBC Credit',
  SHOW_CREDITS = 'Show Credits',
  MVPD = 'MVPD',
  TEMP_PASS = 'Temp Pass',
}

export type StreamRequest = {
  error?: StreamLoaderError
  authType: StreamAuthorizationType
  tokenType?: TOKEN_TYPE
  initialRequest?: boolean
  token?: any
}

const isTempPassConcluded = () => {
  const flag = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass)
  const isTempPassPhase1 = flag
    ? !flag.secondaryTempPassName
    : !DebugControllerSingleton.tempPassName[0]

  if (!flag.identityRequiredForSecondary) {
    return true
  }

  return isTempPassPhase1 || isNBCProfileLinked()
}

export const createLoader = async (
  requests: ((
    request: StreamRequest,
    stream: any,
    program: any,
    lemonade: any,
    isPreCheck: boolean
  ) => Promise<StreamRequest>)[],
  stream: any,
  program: any,
  lemonade: any,
  isPreCheck = false
): Promise<StreamRequest> =>
  requests
    .reduce(
      (acc, req) =>
        acc.then((res) =>
          res.authType === StreamAuthorizationType.UNLOCKED // If unlocked skip
            ? Promise.resolve(res)
            : req(res, stream, program, lemonade, isPreCheck)
        ),
      Promise.resolve({} as StreamRequest)
    )
    .then((res: any) => {
      if (!res.authType) throw res
      if (res.authType !== StreamAuthorizationType.SHOW_CREDITS) {
        openPlayerLoader(stream, program)
        return res
      }
      return res
    })
    .catch((e: StreamRequest) => {
      if (e.error) {
        const error = e.error?.detail
        switch (error) {
          case StreamLoaderErrors.TEMP_PASS_REQUEST_FAILED:
            activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD)
            break
          case StreamLoaderErrors.TEMP_PASS_EXPIRED:
            activateTempPassRedirect(
              isTempPassConcluded()
                ? TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED
                : TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT
            )
            break
          case StreamLoaderErrors.UNAUTHENTICATED:
          case StreamLoaderErrors.NO_CREDITS:
            activateRedirect(e.error as StreamLoaderError, stream, program)
            break
          default:
            throw e
        }
        e.error.handled = true
      }
      throw e
    })
