import { Router } from '@lightningjs/sdk'
import { ENTITLEMENT, ROUTE } from '../constants'
import { ContentGatingType } from '../api/types/bffTypes'
import ModalManager, { ModalTypes } from '../lib/ModalManager'
import AuthenticationSingleton, { AuthenticationEvents } from '../authentication/Authentication'
import { getIdentityRegCode } from '../api/Identity'
import { ACTIVATION_TYPE } from '../widgets/Modals/activation/constants'
import { isNBCProfileLinked } from './auth'
import { Program } from '../store/PlayerStore/PlayerStore'
import { SingleStream } from '../store/PlayerStore/actions'

export const pushHash = (hash: string) => history.pushState(null, '', `#${hash}`)

export const isOlympicsPage = () => Router.getActiveRoute() === ROUTE.olympics

/**
 * This will redirect to content gating modal if applicable
 * @param stream Stream object with contentGatingType and entitlement properties
 * @param program Program data
 * @returns A promise which resolves if playback can start
 * (true if content gating flow completed, false if content gating not applicable)
 */
export const checkContentGating = (stream: SingleStream, program: Program) =>
  new Promise((res, rej) => {
    const isAuthenticated = AuthenticationSingleton.isAuthenticated() || isNBCProfileLinked()
    if (
      isAuthenticated ||
      stream?.entitlement === ENTITLEMENT.ENTITLED ||
      stream?.contentGatingType === ContentGatingType.NOT_APPLICABLE
    ) {
      res(false)
      return
    }
    const successCb = () => res(true)
    getIdentityRegCode().then(({ deviceCode }) => {
      const modalType =
        stream?.contentGatingType === ContentGatingType.HARD
          ? ModalTypes.HARD_CONTENT_GATING
          : ModalTypes.SOFT_CONTENT_GATING

      AuthenticationEvents.addSingleListener(ACTIVATION_TYPE.NBC, successCb)
      AuthenticationEvents.pollIDM(deviceCode)
      ModalManager.open(modalType, {
        type: stream.contentGatingType,
        resolveCallback: () => {
          AuthenticationEvents.removeSingleListener(ACTIVATION_TYPE.NBC, successCb)
          res(true)
        },
        rejectCallback: rej,
        stream,
        program,
      })
    })
  })

export const backToFirstRouteThatIsnt = (route: ROUTE | ROUTE[]) => {
  const exclusions = Array.isArray(route) ? route : [route]
  const check = (r: any) => exclusions.includes(r)
  let step = -1
  const history = Router.getHistory()
  for (let i = history.length - 1, n = 0; i > n; i--) {
    if (!check(history[i]?.hash)) {
      step = -(history.length - i)
      break
    }
  }
  Router.go(step)
}
