import { checkContentGating } from '../../../../helpers'
import { missingDataError, StreamLoaderErrors } from '../error'
import { StreamAuthorizationType, StreamRequest } from '../request'
import { TOKEN_TYPE } from '../../../../constants'
import { Stream, Program } from '../../../../store/PlayerStore/PlayerStore'

export const contentGating = async (
  request: StreamRequest,
  stream: Stream,
  program: Program,
  _: any
) => {
  try {
    if (!stream) throw missingDataError('Stream is undefined')
    if (!('contentGatingType' in stream)) {
      // Can't check content gating if it's not enabled for this stream, skip
      return request
    }
    const contentGatingCompleted = await checkContentGating(stream, program)
    if (contentGatingCompleted) {
      request.authType = StreamAuthorizationType.UNLOCKED
      request.tokenType = TOKEN_TYPE.FREE_CONTENT_GATING
    }
    return request
  } catch (e) {
    request.error = {
      handled: true,
      detail: StreamLoaderErrors.USER_CANCELLED,
      data: e,
    }
    throw request
  }
}
