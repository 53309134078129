import { Language, Lightning, Utils } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'

import TextButton from '../../../components/buttons/TextButton'
import Activation, { ActivationModalData } from './Activation'
import Loader from '../../../components/loader/Loader'

import {
  ALIGN_ITEMS,
  ALIGN_SELF,
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  JUSTIFY_CONTENT,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../constants'
import { ACTIVATION_LANDING } from './constants'

import { useRouterBackDisabled } from './hooks/useRouterBackDisabled'
import ModalManager from '../../../lib/ModalManager'

import { FastImg, getBrandName, setSmooth, topLogoPathMapper } from '../../../helpers'
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { LoadingStateFactory } from './states/LoadingStateFactory'
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory'

// @ts-expect-error TS2417
export default class OnboardingActivation extends Activation {
  override _titleTextKey = 'onboarding_link_msg'
  override _routerBackDisabled = true
  override _hintLineHeight = 30
  _subscription?: Subscription

  static override _template() {
    const brand = getBrandName()
    return {
      w: SCREEN_SIZE.width,
      h: SCREEN_SIZE.height,
      rect: true,
      color: COLORS.dark,
      Background: {
        flexItem: false,
        rect: true,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        src: Utils.asset(`images/onboarding/bg-${brand}.png`),
      },
      ActivationScreen: {
        flex: {
          direction: FLEX_DIRECTION.column,
          paddingTop: 128,
          alignItems: ALIGN_ITEMS.center,
        },
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        LogoWrapper: {
          w: 100,
          h: 100,
          flex: {
            direction: FLEX_DIRECTION.column,
            justifyContent: JUSTIFY_CONTENT.center,
          },
          Image: {
            flexItem: {
              alignSelf: ALIGN_SELF.center,
            },
            texture: FastImg(topLogoPathMapper(brand)).contain(100, 100),
          },
        },
        Title: {
          flexItem: {
            marginBottom: 42,
          },
          text: {
            fontSize: 58,
            fontFace: FONT_FACE.light,
            textColor: COLORS.white,
            lineHeight: 74,
            verticalAlign: VERTICAL_ALIGN.middle,
            maxLines: 2,
            wordWrapWidth: 1384,
          },
        },
        Content: {
          w: SCREEN_SIZE.width,
          h: 520,
          flex: {
            direction: FLEX_DIRECTION.row,
            justifyContent: JUSTIFY_CONTENT.center,
            paddingLeft: 216,
            paddingRight: 293,
          },
          CodeContent: {
            flex: {
              direction: FLEX_DIRECTION.column,
            },
            w: 621,
            h: 509,
            Line1: {
              flex: { direction: FLEX_DIRECTION.row },
              flexItem: {
                marginBottom: 20,
              },
            },
            Line2: {
              flexItem: {
                marginBottom: 38,
              },
              text: {
                fontSize: 30,
                fontFace: FONT_FACE.light,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
                text: Language.translate('activation_line2'),
              },
            },
            CodeBackground: {
              w: 621,
              h: 120,
              rect: true,
              color: COLORS.white7,
              flex: {
                direction: FLEX_DIRECTION.row,
                justifyContent: JUSTIFY_CONTENT.center,
                alignItems: ALIGN_ITEMS.center,
              },
              flexItem: {
                marginBottom: 44,
              },
              shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 7,
              },
              Code: {
                y: 8,
                text: {
                  fontSize: 88,
                  fontFace: FONT_FACE.light,
                  textColor: COLORS.white,
                  textAlign: TEXT_ALIGN.center,
                },
              },
              CodeLoader: {
                visible: true,
                x: 180,
                y: 22,
                flexItem: false,
                type: Loader,
              },
            },
            GenerateButton: {
              flexItem: {
                marginBottom: 20,
              },
              w: 420,
              h: 50,
              type: TextButton,
              radius: 26,
              fontSize: 26,
              fontFace: FONT_FACE.regular,
              focusFontColor: COLORS.black,
              unfocusFontColor: COLORS.warmGray2,
              focusBackGroundColor: COLORS.white,
              unfocusBackgroundColor: COLORS.transparent,
              label: Language.translate('gen_new_code').toUpperCase(),
              strokeWidth: 2,
              strokeColor: COLORS.warmGray2,
              padding: 34,
            },
            CancelButton: {
              w: 420,
              h: 50,
              type: TextButton,
              radius: 26,
              fontSize: 26,
              fontFace: FONT_FACE.regular,
              focusFontColor: COLORS.black,
              unfocusFontColor: COLORS.warmGray2,
              focusBackGroundColor: COLORS.white,
              unfocusBackgroundColor: COLORS.transparent,
              label: Language.translate('onboarding-no-thanks').toUpperCase(),
              strokeWidth: 2,
              strokeColor: COLORS.warmGray2,
              padding: 34,
            },
          },
          DividerContent: {
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
            },
            flexItem: {
              marginLeft: 98,
            },
            w: 51,
            h: 556,
            Divider1: {
              w: 2,
              h: 228,
              rect: true,
              color: COLORS.dark6,
            },
            Or: {
              text: {
                fontSize: 42,
                fontFace: FONT_FACE.light,
                lineHeight: 100,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
                textAlign: TEXT_ALIGN.center,
                text: Language.translate('OR'),
              },
            },
            Divider2: {
              w: 2,
              h: 228,
              rect: true,
              color: COLORS.dark6,
            },
          },
          QRContent: {
            flex: {
              direction: FLEX_DIRECTION.column,
              alignItems: ALIGN_ITEMS.center,
            },
            flexItem: {
              marginLeft: 234,
            },
            UsePhone: {
              text: {
                fontSize: 28,
                fontFace: FONT_FACE.light,
                textColor: COLORS.white,
                textAlign: TEXT_ALIGN.center,
                text: Language.translate('use_phone_to_scan'),
              },
            },
            w: 410,
            h: 509,
            QRPlaceHolder: {
              flexItem: false,
              y: 59,
              x: (w: number) => w / 2,
              mountX: 0.5,
              texture: Lightning.Tools.getRoundRect(400, 400, 0, 1, COLORS.dark6, false, 0),
            },
            QRCode: {
              flexItem: false,
              y: 59,
              x: (w: number) => w / 2,
              mountX: 0.5,
              w: 400,
              h: 400,
            },
          },
        },
      },
    }
  }

  override set modalData(modalData: ActivationModalData) {
    useRouterBackDisabled().set(true)
    this.landing = ACTIVATION_LANDING.NBC
    this._program = modalData.program
    this._stream = modalData.stream
    this._referrerType = modalData.referrer
    this._closeCallback = modalData.closeCallback
    this._start()
  }

  override _active() {
    setSmooth(this.tag('ActivationScreen'), 'alpha', 1)
    this._subscription = new SubscriptionBuilder()
      .with({
        type: SubscriptionSources.AUTHENTICATION,
        handler: this._onAuthEvent,
      })
      .subscribe()
  }

  private _onAuthEvent = (): void => {
    setSmooth(this.tag('ActivationScreen'), 'alpha', 0)
  }

  override _inactive() {
    this._subscription?.unsubscribe()
  }

  override _handleKey(): boolean {
    return true
  }

  static override _states() {
    return [
      LoadingStateFactory(this),
      NewCodeStateFacrory(this),
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleEnter(): void {
          sendMetric(EVENTS.CLICK, {
            name: 'Maybe Later',
          })
          ModalManager.close()
        }
        override _handleUp() {
          this._setState('NewCode')
        }
      },
    ]
  }
}
